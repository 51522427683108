<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- Job create section -->
            <section class="Job-create-section section-gap-30">
                <div class="section-title-wrap flex-wrap">
                    <div class="section-title" style="line-height: 1.5">{{ $t("Job Edit")}} - {{currentJob.title}}
                    </div>
                    <v-popover offset="10" :disabled="false">
                        <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                        <template slot="popover">
                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                            <a v-close-popover :href="link" target="_blank">
                                {{ $t(msg) }}
                            </a>
                        </template>
                    </v-popover>
                    <div class="d-flex ml-auto text-right">
                        <button class="button warning-button mb-2 ml-2" @click.prevent="jobPreview">{{ $t("Preview")}}</button>
                        <button class="button success-button mb-2 ml-2" @click.prevent="checkAndPublishJob" v-if="editMode && !isPublished && canPublishJob">{{ rePublish ? $t('republish') : $t('Publish') }}</button>
                        <button class="button info-button mb-2 ml-2 text-capitalize" @click.prevent="archiveJob(currentJobId)" v-if="editMode && isPublished">{{ $t("archive")}}</button>

                        <button class="button primary-button mb-2 ml-2"
                                :class="{'disabled' : !editMode}"
                                @click.prevent="showCustomizeFieldModal"
                        >{{ $t("Customize Fields")}}
                        </button>
                    </div>
                </div>
                <ul class="progress-step-group">
                    <li class="progress-step__wrap">
                        <router-link :to="{name: 'job.edit', params: {id: currentJobId}}" class="progress-step" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("information")}}</div>
                        </router-link>
                    </li>
                    <li class="progress-step__wrap" v-if="company.package_rule.screening">
                        <router-link :to="{name: 'job.screening', params: {id: currentJobId}}" class="progress-step" :class="{'disabled' : !editMode}" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("Screening Questions")}}</div>
                        </router-link>
                    </li>
                    <li class="progress-step__wrap" v-if="company.package_rule.quiz">
                        <router-link :to="{name: 'job.quiz', params: {id: currentJobId}}" class="progress-step" :class="{'disabled' : !editMode}" exact-active-class="progress-step--activated" exact>
                            <div class="progress-step__icon">
                                <div class="eicon e-tick"></div>
                            </div>
                            <div class="progress-step__text">{{ $t("Quiz")}}</div>
                        </router-link>
                    </li>
                </ul>
                <router-view></router-view>
            </section>
        </div>
        <modal-required-fields
                v-if="modal.show"
                :job="currentJob"
                @closeModal="hideCustomizeFieldModal"
                :publishable="publishable"
        />

        <modal-job-preview
                :preview-url="currentJob.preview"
                v-if="showJobPreview"
                @closeModal="hideJobPreview"
        />
        <modal-job-share
                :job="currentJob"
                v-if="showShareModal"
                @closeModal="hideShareModal"
        ></modal-job-share>

    </dashboard-layout>
</template>
<script>
    const DashboardLayout = () => import("./DashboardLayout");
    const ModalRequiredFields = () => import("../components/modal/job/RequiredFields");
    const ModalJobPreview = () => import("../components/modal/job/Preview");
    const ModalJobShare = () => import("../components/modal/job/Share");

    import {EventBus} from "../extra/event-bus";
    import {
        CHECK_AND_PUBLISH_JOB,
        OPEN_JOB_SHARE_MODAL,
        SHOW_JOB_TEMP_PREVIEW,
        UPDATE_JOB_INFO
    } from "../constants/events";
    import {JOB} from "../extra/constants";
    import {getJobBasicData} from "../app/api/CommonRequest";
    import {mapState} from 'vuex';
    import {canPublishJob} from "../config/permission";

    export default {
        components: {
            DashboardLayout,
            ModalRequiredFields,
            ModalJobPreview,
            ModalJobShare
        },
        data() {
            return {
                currentJob: {},
                showJobPreview: false,
                editMode: false,
                publishable: false,
                isPublished: false,
                modal: {
                    fade: false,
                    show: false,
                },
                showShareModal: false,
                msg: 'How to fill out job information?',
                link: 'https://easy.jobs/docs/create-a-new-job-post/'

            }
        },
        computed: {
            ...mapState(['company']),
            currentJobId() {
                return this.$route.params.id;
            },
            rePublish() {
                if (this.currentJob) {
                    return this.currentJob.status === JOB.STATUS.ARCHIVED;
                }
                return false;
            },
            canPublishJob() {
                return canPublishJob();
            }
        },
        methods: {
            showCustomizeFieldModal() {
                this.modal.show = true;
                const el = document.body;
                el.classList.add('modal-open');
            },
            hideCustomizeFieldModal() {
                this.modal.show = false;
                const el = document.body;
                el.classList.remove('modal-open');
            },
            jobPreview() {
                if (this.$route.name === 'job.edit') {
                    EventBus.$emit(SHOW_JOB_TEMP_PREVIEW);
                } else {
                    this.showJobPreview = true;
                    const el = document.body;
                    el.classList.add('modal-open');
                }

            },
            hideJobPreview() {
                this.showJobPreview = false;
                const el = document.body;
                el.classList.remove('modal-open');
            },

            checkAndPublishJob() {
                if (!this.isPublished) {
                    if (this.hasRequiredFields(this.currentJob)) {
                        this.publishJob(this.currentJob);
                    } else {
                        this.publishable = true;
                        this.showCustomizeFieldModal();
                    }
                }
            },
            hasRequiredFields(job) {
                if (job) {
                    return (job.meta.apply_rules);
                }
                return false;
            },

            hasMandatoryFieldData(job) {
                if (job.id &&
                    job.title &&
                    job.slug &&
                    job.details &&
                    job.category_id &&
                    job.skills.length &&
                    job.employment_type &&
                    job.experience_level &&
                    job.salary_type &&
                    job.expire_at) {
                    return true;
                }
                return false;
            },

            async findCurrentJobBasicData() {
                if (this.$route.params.id) {
                    this.editMode = true;
                }
                if (this.editMode) {
                    await getJobBasicData(this.$route.params.id).then((data) => {
                        this.currentJob = data;
                        this.isPublished = this.currentJob.status === JOB.STATUS.PUBLISHED;
                    });
                } else {
                    this.showEditor = true;
                }
            },
            openShareModal(job) {
                this.showShareModal = true;
                const el = document.body;
                el.classList.add('modal-open');
            },
            hideShareModal() {
                this.showShareModal = false;
                const el = document.body;
                el.classList.remove('modal-open');
            },
        },
        async mounted() {
            await this.findCurrentJobBasicData();
        },
        async created() {
            EventBus.$on(UPDATE_JOB_INFO, () => {
                this.findCurrentJobBasicData();
            });

            EventBus.$on(CHECK_AND_PUBLISH_JOB, () => {
                this.checkAndPublishJob();
            });
            EventBus.$on(OPEN_JOB_SHARE_MODAL, (job) => {
                this.findCurrentJobBasicData();
                this.openShareModal(job);
            });
        },
        beforeDestroy() {
            EventBus.$off(CHECK_AND_PUBLISH_JOB);
            EventBus.$off(UPDATE_JOB_INFO);
            EventBus.$off(OPEN_JOB_SHARE_MODAL);
        },
        updated() {
            if(this.$route.name === 'job.edit') {
                this.msg = 'How to fill out job information?';
                this.link = 'https://easy.jobs/docs/create-a-new-job-post/';
            }else if(this.$route.name === 'job.screening') {
                this.msg = 'How to add screening questions?';
                this.link = 'https://easy.jobs/docs/how-to-add-screening-questions-in-easy-jobs/';
            }else if(this.$route.name === 'job.quiz') {
                this.msg = 'How to add quiz questions?';
                this.link = 'https://easy.jobs/docs/how-to-add-a-quiz-for-your-job-posts-inside-easy-jobs/';
            }
        }
    }
</script>

<style scoped>
    .disabled {
        pointer-events: none;
    }

    .section-title {
        text-transform: unset;
    }
</style>
